import { Component, OnInit, OnDestroy } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { INote } from '../../note';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'rz-single-note',
  templateUrl: './single-note.component.html',
  styleUrls: ['./single-note.component.scss']
})
export class SingleNoteComponent implements OnInit {

  doc: Observable<any>;
  note: INote = null;
  senderUsername: string;

  constructor(private db: AngularFirestore, private route: ActivatedRoute, private auth: AuthService) {}


  ngOnInit() {
    // first grab the document id from the URL parameter
    // then get the document from Firestore
    this.doc = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        return this.db.collection('notes').doc(params.get('id')).valueChanges()
      })
    );
    this.doc.subscribe(x => {
      this.note = x;
      // get info about the person who sent it
      if (this.note.senderUserID) {
        this.auth.getUserByID(this.note.senderUserID).subscribe(
          array => {
            this.senderUsername = array[0].username;
          }
        );
      } else {
        // the person wanted to remain anonymous
        this.senderUsername = "Anonymous";
      }
    });
  }// ngOnInit()


  // ngOnDestroy(): void {
  //   this.doc.unsubscribe();
  // }
}
