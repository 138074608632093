import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MessagingService } from '../../core/messaging.service';
import { AuthService } from '../../core/auth.service';
import { filter, take } from 'rxjs/operators';
import { PaginationService } from 'src/app/core/pagination.service';


@Component({
  selector: 'view-notes',
  templateUrl: './view-notes.component.html',
  styleUrls: ['./view-notes.component.scss']
})
export class ViewNotesComponent implements OnInit {

  constructor(
    public db: AngularFirestore,
    public msg: MessagingService,
    public auth: AuthService,
    public page: PaginationService
    ) {} // constructor


  ngOnInit() {
    // get notes
    // this.load();

    this.auth.user$
      .pipe(
        take(1),
        filter(user => !!user))
        .subscribe(user => {
          if (user) {
            this.msg.getPermission(user);
            this.msg.monitorRefresh(user);
            this.msg.receiveMessages();
          }
        });



    // setup the pagination initial values
    this.page.init('notes', 'created', { reverse: true, prepend: false });
  }// ngOnInit

  // handle the scrollingw
  scrollHandler(e) {
    if (e === 'bottom') {
      this.page.more();
    }

    if (e === 'top') {
      // top reached
    }
  }// scrollHandler

}// end of class

