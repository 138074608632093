import { Component, OnInit } from '@angular/core';
import { IVehicle } from '../vehicles/vehicle';
import { AuthService } from '../core/auth.service';
import { VehicleService } from '../vehicles/vehicle.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'rz-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  currentUserID: string;
  userDocument: any = {};
  registeredVehicles: IVehicle[] = [];

  constructor(private auth: AuthService, private vehicleService: VehicleService) { }


profileForm = new FormGroup({
    username: new FormControl(), // refernce it with this.profileForm.value.username
});

vehicleForm = new FormGroup({
  country: new FormControl(),
  state: new FormControl(),
  license: new FormControl()
});

ngOnInit() {
    // get the logged user info
    this.auth.user$.subscribe(
      (user) => {
        // console.log(user);
        if (user) {
          // get the user id
          this.currentUserID = user.uid;
          // console.log(this.currentUserID);
          this.userDocument = user;
          // this.getRegisteredVehicles();

        }
      }
    );


    this.vehicleService.getUserVehicles().subscribe(
      vehicle => {
        this.registeredVehicles.push(vehicle);
      }
    );
}

updateProfile() {}

  // register a new vehicle entered by the user
  // checks if the vehicle exist, if not, adds it
  // then attaches reference to the vehicle in the user's registeredVehicles subcolletion
  // then attaches reference to the user to the vehicle's registeredUsers subcollection
  // TODO move this to the vehicle service
  registerVehicle() {
    let newVehicle: IVehicle;
    newVehicle = {
      country: this.vehicleForm.value.country,
      state: this.vehicleForm.value.state,
      license: this.vehicleForm.value.license
    };

    // if values are empty, do nothing
    if (!newVehicle.country || !newVehicle.state || !newVehicle.license) {
      return;
    }

    // console.log(newVehicle);

    // check if vehicle exists
    this.vehicleService.exists(newVehicle)
    .subscribe((res: any) => {
        // console.log(res);
        if (res.docExists) {
          // console.log('it exists', res);
          // vehicle exists already
          // subscribe to it
          this.vehicleService.subscribeToVehicle(res.vid, this.currentUserID);

        } else {
          // console.log('it doesnt exists', res);
          // vehicle doesnt exists
          // add vehicle
          // subscribe to it
          this.vehicleService.addVehicle(newVehicle)
          // subscribe this vehicle to the logged in user
          .subscribe(newDocID => this.vehicleService.subscribeToVehicle(newDocID, this.currentUserID));

        } // else
      }
    ); // subscribe

  } // registerVehicle()




}
