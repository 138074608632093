import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { IVehicle } from './vehicle';
import { Observable, from} from 'rxjs';
import { map, take, tap, switchMap, mergeMap } from 'rxjs/operators';
import { INote } from '../notes/note';
import { AuthService } from '../core/auth.service';


@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(private afs: AngularFirestore, private auth: AuthService) { }


  // adds a new vehicle
  addVehicle(newVehicle: IVehicle): Observable<string> {
    // document ID for the this new document
    const newID: string = this.afs.createId();
    // server timestamp
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();

    // add server datestamp
    newVehicle.created = timestamp;

    // add vehicle id
    newVehicle.vid = newID;

    // create vechicle if it doesnt exist.
    // adds the new vehicle, then returns the new vehicle/document ID
    return from(this.afs.collection('vehicles').doc(newID).set(newVehicle).then(() => newID));
  }// addVehicle




  // return an observable boolean wether the document exists
  exists(query: IVehicle): Observable<any> {
    return this.afs.collection('vehicles',
        ref => ref
        // queries
        .where('country', '==', query.country)
        .where('state', '==', query.state)
        .where('license', '==', query.license)
      ).snapshotChanges()
      .pipe(
        take(1),
        // check that theres a document
        tap(res => console.log('vehicle service: snapshot response', res.length, res)),

        // check if there are documents, send response
        // if there is a document, send also the doc id
        map((res: any) => {
           const queryResponse: any = {};
           // captures if the document exists
           const docExists: boolean = res.length > 0;
           queryResponse.docExists = docExists;
           queryResponse.vid = null;
           if (docExists) {
             // if it exists, add the doc id
             queryResponse.vid = res[0].payload.doc.id;
           }
           return queryResponse;
          })
      ); // pipe
  }// end exists()




  // attaches user to a vehicle
  subscribeToVehicle(vehicleId: string, userId: string) {
    console.log(vehicleId, userId);
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();

    // TODO CREA UN SUBSCRIPTION AL AUTH SERVICE, LEE EL USERID DE AHÍ

    // add user to collection vehicles/subscribers
      this.afs.collection('vehicles').doc(vehicleId).collection('registeredUsers').doc(userId).set({
        uid: userId,
        subscribedOn: timestamp
      });
    // add car to collection users/subscriptions
      this.afs.collection('users').doc(userId).collection('registeredVehicles').doc(vehicleId).set({
        vid: vehicleId,
        registeredOn: timestamp
      });
  }// endof subscribeToVehicle






  // get all the notes where the vehicleID is equal to the parameter
  notes(vehicleId: string): Observable<INote[]> {
    return this.afs.collection('notes', ref => ref
      .where('vehicleID', '==', vehicleId)
      .orderBy('created', 'desc')
    ).valueChanges() as Observable<INote[]>;
  }






  getVehicleDocument(vehicleID: string): Observable<IVehicle> {
    return this.afs.collection('vehicles').doc(vehicleID).valueChanges() as Observable<IVehicle>;
  }





  getUserVehicleIDs(userID: string) {
    return this.afs.collection('users').doc(userID).collection('registeredVehicles').valueChanges();
    // TODO use the vid and pull the full vehicle information, then return it
  }






  getUserVehicles(): Observable<IVehicle> {
    // read the authenticated user
    // from that, read the registered vehicles
    // from each vehicle, read the notes sent to them
    // checks the authenticaed user,
    // gets the registered vehicles
    return this.auth.user$.pipe(
      switchMap(user => this.getUserVehicleIDs(user.uid)), // return all the docs from the registered vehicle sub-collection
      // tap(x => console.log(x)), // testing
      mergeMap(arrayOfVehicleIDs => arrayOfVehicleIDs), // breaks the array appart
      // tap(x => console.log(x)),

      mergeMap((registrationDoc) => this.getVehicleDocument(registrationDoc.vid)),

    );
  } // getUserVehicles();


}// endof VehicleService class
