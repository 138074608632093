import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SendNoteComponent} from './send/send-note.component';
import { ViewNotesComponent } from './notes/view/view-notes.component';
import { SingleNoteComponent} from './notes/view/single-note/single-note.component';
import { AuthGuard } from './core/auth.guard';
import { LoginComponent } from './users/login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { InboxComponent } from './notes/inbox/inbox.component';
import { SettingsComponent } from './settings/settings.component';
import { VehicleComponent } from './vehicles/vehicle.component';

const routes: Routes = [
  {
    path: 'send',
    component: SendNoteComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'u/:username',
    component: ProfileComponent
  },
  {
    path: 'i',
    component: InboxComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'n/:id',
    component: SingleNoteComponent
  },
  {
    path: 'v/:id',
    component: VehicleComponent
  },
  {
    path: 'n',
    component: ViewNotesComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'settings',
    component: SettingsComponent
  },
  {
    path: '',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
