import { Component, OnInit } from '@angular/core';
import { INote } from '../notes/note';
import { FormGroup, FormControl } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { AuthService } from '../core/auth.service';

// states
import './locations';
import { LOCATIONS } from './locations';


@Component({
  selector: 'app-send-note',
  templateUrl: './send-note.component.html',
  styleUrls: ['./send-note.component.scss']
})
export class SendNoteComponent implements OnInit {
  currentUserName: string;
  currentUserID: string;
  senderUserId: string;
  sendNoteForm = new FormGroup({
    // country: new FormControl(),
    state: new FormControl(),
    license: new FormControl(),
    message: new FormControl(),
    anonymous: new FormControl(),
    public: new FormControl(),
  });
  options: any[] = new LOCATIONS().USA;
  // filteredOptions: Observable<string[]>;
  // LOCATION = new LOCATIONS();


  constructor(private afs: AngularFirestore, private auth: AuthService) {

    // by default, make the note not-anonymous and public
    this.sendNoteForm.controls['anonymous'].setValue(false);
    this.sendNoteForm.controls['public'].setValue(true);
    // // get all the states
    // this.LOCATION.USA.forEach(element => {
    //   this.options.push(element.name);
    // });

  }// constructor




  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   return this.options.filter(option => option.toLowerCase().includes(filterValue));
  // }



  ngOnInit() {
    // auth
    this.auth.user$.subscribe(
      (user) => {
        if (user) {
          this.currentUserName = user.displayName;
          this.currentUserID = user.uid;
          return user;
        }
      }
    );

    // //
    // this.filteredOptions = this.sendNoteForm.controls['state'].valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filter(value))
    // );

  }// ngOnInit()




  sendNote() {
    let newNoteDoc: INote;
    let formIncomplete: boolean;

    newNoteDoc = this.sendNoteForm.value;
    // add the user id of the sender
    newNoteDoc.senderUserID = this.currentUserID;
    // is this post anonymous?
    // note: the user id (uid) will still be attached to the note on the back-end,
    // but will not be displayed on the site.
    // i might have to revisit this to make it really anonymous.
    newNoteDoc.anonymous = this.sendNoteForm.controls['anonymous'].value;
    // make the license uppercase
    newNoteDoc.license = this.cleanTag(newNoteDoc.license);
    // add the created server time
    newNoteDoc.created = firebase.firestore.FieldValue.serverTimestamp();
    // note id
    newNoteDoc.noteID = this.afs.createId(); // create an unique id that will be used as the document id and added as a property
    // note public
    newNoteDoc.public = this.sendNoteForm.controls['public'].value;

    // adds a new document in the Notes collection if all required fields are exist
    // iterate the property and mark if at least one is empty
    if (!this.sendNoteForm.value['state'] || 
        !this.sendNoteForm.value['license'] || 
        !this.sendNoteForm.value['message']) {
      formIncomplete = true;
    }
    // add doc if form is not incomplete
    if (!formIncomplete) {
      // form is incomplete
      this.afs.collection('notes').doc(newNoteDoc.noteID).set(newNoteDoc); // TODO add a Toast message to notify success sending note
      // clear the message box
      this.sendNoteForm.controls['message'].setValue(null);
    }
  }

  // cleans the tag number entered
  // returns tag number in uppercase and without special characters
  cleanTag(input) {
    if (input) {

      let cleanedTag = input;
      // assigns invalid characters
      const iChars = /\$|,|@|#|~|`|\%|\*|\^|\&|\(|\)|\+|\=|\[|\_|\]|\[|\}|\{|\;|\:|\'|\"|\<|\>|\?|\||\\|\!|\$|\./g;

      cleanedTag = cleanedTag.toUpperCase();
      cleanedTag = cleanedTag.replace(/ /g, ''); // removes spaces
      cleanedTag = cleanedTag.replace(iChars, ''); // remove special characters
      return cleanedTag;
    } // if
  }



}// class
