import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// firebase
import { AngularFireModule} from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';

import { NotifyService } from './core/notify.service';
import { AuthService } from './core/auth.service';

// notes
import { ViewNotesComponent } from './notes/view/view-notes.component';
import { SendNoteComponent } from './send/send-note.component';

// users
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './users/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TagComponent } from './vehicles/tag/tag.component';
import { SingleNoteComponent } from './notes/view/single-note/single-note.component';
import { HomeComponent } from './home/home.component';
import { ScrollableDirective } from './core/scrollable.directive';
import { InboxComponent } from './notes/inbox/inbox.component';
import { RegistrationComponent } from './registration/registration.component';
import { SettingsComponent } from './settings/settings.component';
import { VehicleComponent } from './vehicles/vehicle.component';

@NgModule({
  declarations: [
    AppComponent,
    SendNoteComponent,
    ViewNotesComponent,
    LoginComponent,
    ProfileComponent,
    TagComponent,
    SingleNoteComponent,
    HomeComponent,
    ScrollableDirective,
    InboxComponent,
    RegistrationComponent,
    SettingsComponent,
    VehicleComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,

    // firebase
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(), // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule
  ],
  providers: [
    AuthService,
    NotifyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
