import { Component, OnInit } from '@angular/core';
// import { AuthService } from 'src/app/core/auth.service';
import { VehicleService } from 'src/app/vehicles/vehicle.service';
import { INote } from '../note';
import { Observable } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { IVehicle } from 'src/app/vehicles/vehicle';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit {
  inbox$: Observable<INote[]>;
  selectedVehicle: IVehicle = null;
  userVehicles: IVehicle[] = [];

  inboxForm = new FormGroup({
    vehicle: new FormControl(),
  });

  constructor(
    private vehicleService: VehicleService,
  ) { }

  ngOnInit() {


    this.vehicleService.getUserVehicles().subscribe(
      vehicle => {
        // console.log(vehicle)
        this.userVehicles.push(vehicle);

        // set a default vehicle
        this.inboxForm.controls['vehicle'].setValue(this.userVehicles[0].vid);
      }
    );

    // when a new value is selected, pull the notes for that vehicle
    this.inboxForm.controls['vehicle'].valueChanges.subscribe(
      value => {
        // set the selected vehicle
        this.vehicleService.getVehicleDocument(value).pipe(
          take(1)
          ).subscribe(
            vehicle => {
            // console.log(vehicle);
            this.selectedVehicle = vehicle;
          }
        );

        // populate inbox
        this.inbox$ = this.vehicleService.notes(value);

      }
    );


  } // ngOnInit

}
