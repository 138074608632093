import { Directive, HostListener, EventEmitter, Output, ElementRef } from '@angular/core';


// lets us know when an element container has been scrolled
// will be used to load more notes
@Directive({
  selector: '[scrollable]'
})
export class ScrollableDirective {

  @Output() scrollPosition = new EventEmitter();
  constructor(public el: ElementRef) {}

  @HostListener('window:scroll')
  onScroll() {
    try {
      const elementHeight = this.el.nativeElement.offsetHeight; // height of the containing div
      const distanceFromTop = this.getPosition(this.el); // the vertical position from top
      // the distance scrolled and adding the height of the window
      const windowScrolledPlusHeight = window.scrollY + window.innerHeight;

      // emit bottom event
      if (windowScrolledPlusHeight > distanceFromTop + elementHeight - 1) {
        this.scrollPosition.emit('bottom');
      }

      // emit top event
      if (window.scrollY < distanceFromTop) {
        this.scrollPosition.emit('top');
      }

    } catch (err) {
      console.log('Error in Scrollable directive: ' + err);
    }
  }

  // gets the position of element
  getPosition(element) {
    element = element.nativeElement;
    let yPosition = 0;

    while (element) {
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
    }
    return yPosition;
}
}// ScrollableDirective
