import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { IUser } from '../users/user';



@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  user: IUser = null;

constructor(private afs: AngularFirestore, private route: ActivatedRoute, private auth: AuthService) {}




ngOnInit() {

    // get username from URL, display their profile
    this.route.paramMap.subscribe((params: ParamMap) => {
      const parameter = params.get('username');
      // console.log(params.get('username'));
      this.auth.getUserByUsername(parameter).subscribe(
        (userDocArray: IUser[]) => {
          // console.log(userDocArray);
          if (userDocArray.length > 0) {
            this.user = userDocArray[0];
          } else {
            // if user arrives here by the uid instead of username,
            // need to search the user by uid
              this.searchByUID(parameter);
          }
        }
      );
    });

} // ngoninit


  searchByUID (parameter: string) {
    this.auth.getUserByID(parameter).subscribe(
      (userDocArray: IUser[]) => {
        // console.log(userDocArray);
        if (userDocArray.length > 0) {
          this.user = userDocArray[0];
        }
      }
    );
  }

} // endof class
