import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of, empty } from 'rxjs';
import { switchMap, take, map, tap, mergeMap} from 'rxjs/operators';

import { IUser } from '../users/user';




@Injectable()
export class AuthService {
  providedIn: 'root';
  user$: Observable<IUser>;

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private router: Router) {
    // Get auth data, then get firestore user document || null
      this.user$ = this.afAuth.authState.pipe(
        switchMap(user => {
          if (user) {

            // return the user document observable
            return this.afs.doc<IUser>(`users/${user.uid}`).valueChanges();

          } else {
            return of(null);
          }
        }) // endof switchmap
      ); // endof pipe()
  } // endof constructor



  async googleSignin() {
    const provider = new auth.GoogleAuthProvider();
    const credential = await this.afAuth.auth.signInWithPopup(provider);
    // return this.updateUserData(credential.user);
    return credential.user;
  }

  async signOut() {
    await this.afAuth.auth.signOut();
    this.router.navigate(['/']);
  }


  updateUserData(user: IUser) {
    // Sets user data to firestore on login
    // const data: IUser = {
    //   uid: user.uid,
    //   email: user.email,
    //   displayName: user.displayName,
    // };
    return this.afs.doc(`users/${user.uid}`).set(user, { merge: true }); // the merge:true is used to only update the data
  }

  /**
   * Returns user document based onthe username
   * @param username
   */
  getUserByUsername(username: string): Observable<IUser[]> {
    return this.afs.collection('users', ref => ref
      // queries
      .where('username', '==', username)
    ).valueChanges().pipe(
      // tap(x => console.log(x)),
      take(1)
    ) as Observable<IUser[]>;
  }

  /**
   * Returns user document based onthe user id
   * @param uid
   */
  getUserByID(uid: string): Observable<IUser[]> {
    return this.afs.collection('users', ref => ref
      // queries
      .where('uid', '==', uid)
    ).valueChanges().pipe(
      // tap(x => console.log(x)),
      take(1),
    ) as Observable<IUser[]>;
  }

}
