import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'rz-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  logged: Observable<boolean>;

  constructor(public auth: AuthService) { }

  ngOnInit() {
    // guard used on the template. if the user is not logged in, show the registration form.
    this.logged = this.auth.user$.pipe(
      take(1),
      map(user => !!user),
    );
    // console.log(this.guard.canActivate());
  }


}
