import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './core/auth.service';
import { take, map } from 'rxjs/operators';
import { IUser } from './users/user';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss'
  ]
})
export class AppComponent implements OnInit{
  logged: Observable<boolean>;
  user: Observable<IUser> = this.auth.user$;

  constructor(public auth: AuthService) { }

  ngOnInit() {
    // guard used on the template. if the user is not logged in, show the registration form.
    this.logged = this.auth.user$.pipe(
      take(1),
      map(user => !!user),
    );

    // console.log(this.guard.canActivate());
  }
}
