import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../core/auth.service';
import { AuthGuard } from '../core/auth.guard';

@Component({
  selector: 'rz-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  registrationForm = new FormGroup({
    name: new FormControl(),
    username: new FormControl(),
    email: new FormControl(),
  });
  newUser: any = {}; // temporary holds new user registration info

  constructor(public auth: AuthService, public guard: AuthGuard) { }

  ngOnInit() {}

  // logs into google account
  // grabs user name and email
  loginWithGoogle() {
    this.auth.googleSignin().then(credential => {
      this.registrationForm.controls['name'].setValue(credential.displayName);
      this.registrationForm.controls['email'].setValue(credential.email);
      this.registrationForm.controls['email'].disable();
      this.newUser.uid = credential.uid;
    });
    // console.log(x);
  }

  // sets or updates
  submit() {
    console.log(this.registrationForm.value);

    this.newUser.displayName = this.registrationForm.controls['name'].value;
    this.newUser.email = this.registrationForm.controls['email'].value;
    this.newUser.username = this.registrationForm.controls['username'].value;

    this.auth.updateUserData(this.newUser);
  }

}
