import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'rz-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {

  constructor() { }
  @Input() vehicle;

  ngOnInit() {

  }

}
